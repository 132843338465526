import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { customerActions } from '../../store/reducers/customer'
import { orderActions } from '../../store/reducers/order'
import Addresses from '../../utils/adresses.json'
import './AddressModal.scss'
export const AddressModal = ({ toggle }: any) => {
    const { address: _Address } = useTypedSelector(
        (state) => state.customerReducer
    )
    const [address, setAddress] = useState(
        _Address || {
            city: 'Красноярск',
            street: '',
            home: '',
            flat: 0,
            floor: 0,
            entrance: 0,
        }
    )

    const { orderCheck } = useTypedSelector((state) => state.orderReducer)
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    const { checkDelivery } = useActions()
    useEffect(() => {
        // eslint-disable-next-line no-undef
        let interval: string | number | NodeJS.Timeout | undefined

        if (address) {
            const { city, street, home } = address
            if (
                street &&
                street.trim().length > 2 &&
                home &&
                home.trim().length > 0
            ) {
                interval = setTimeout(() => {
                    checkDelivery({
                        city: city!,
                        street,
                        home,
                        conceptId: currentConcept?._id || '',
                    })
                }, 1000)
            }
        }

        return () => {
            if (interval) clearInterval(interval)
        }
    }, [address.street, address.home])

    const streetsRef = useRef<HTMLInputElement>(null)
    const streetsListRef = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const onChangeStreet = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value },
        } = e
        streetsListRef.current!.innerHTML = ''
        dispatch(orderActions.clearOrderCheck())
        setAddress((prev) => ({
            ...prev,
            street: e.target.value,
        }))
        if (value.length > 2) {
            const _address = Addresses.find(
                (ad) => ad.city.name === address.city
            )
            const matchedStreets = _address?.streets.filter((street) =>
                street.name.toLowerCase().includes(value.toLowerCase())
            )

            if (streetsListRef.current) {
                matchedStreets?.map((str) => {
                    const p = document.createElement('p')
                    p.classList.add('suggest-address')
                    p.innerText = str.name
                    p.onclick = () => {
                        setAddress((prev) => ({
                            ...prev,
                            street: str.name,
                        }))
                        streetsListRef.current!.textContent = ''
                    }
                    streetsListRef.current!.append(p)
                    return str
                })
            }
        }
    }
    const saveAddress = () => {
        dispatch(customerActions.setAddress(address))
        dispatch(orderActions.clearOrderCheck())
        toggle()
    }
    return (
        <div className='modal-order-address'>
            <h1 className='modal-order-address__header'>Адрес доставки</h1>
            <button className='modal-order-address--close' onClick={toggle}>
                &times;
            </button>
            <div className='modal-order-address-grid'>
                <div className='modal-order-address-grid--left'>
                    <span>г. {address.city}</span>
                    <div className='modal-order-address__inputs'>
                        <input
                            className='modal-order-address__inputs-input --street'
                            value={address.street}
                            placeholder='Улица'
                            onChange={onChangeStreet}
                            ref={streetsRef}
                        />
                        <input
                            className='modal-order-address__inputs-input --home'
                            value={address.home}
                            placeholder='Дом'
                            onChange={(e) => {
                                setAddress((prev) => ({
                                    ...prev,
                                    home: e.target.value,
                                }))
                            }}
                        />
                        <input
                            className='modal-order-address__inputs-input --flat'
                            value={address.flat || ''}
                            placeholder='Кв.'
                            onChange={(e) => {
                                setAddress((prev) => ({
                                    ...prev,
                                    flat: +e.target.value,
                                }))
                            }}
                        />
                        <input
                            className='modal-order-address__inputs-input --floor'
                            value={address.floor || ''}
                            placeholder='Этаж'
                            onChange={(e) => {
                                setAddress((prev) => ({
                                    ...prev,
                                    floor: +e.target.value,
                                }))
                            }}
                        />
                        <input
                            className='modal-order-address__inputs-input --entrance'
                            value={address.entrance || ''}
                            placeholder='Подъезд'
                            onChange={(e) => {
                                setAddress((prev) => ({
                                    ...prev,
                                    entrance: +e.target.value,
                                }))
                            }}
                        />
                    </div>
                    <button
                        className='modal-order-address--submit'
                        disabled={!orderCheck?.areaName}
                        onClick={saveAddress}>
                        Сохранить
                    </button>
                    {orderCheck && (
                        <div className='modal-order-address__delivery-check'>
                            {orderCheck.areaName ? (
                                <p>
                                    Ваш адрес находится в зоне доставки для
                                    заказов от {orderCheck.minOrderSum}₽
                                </p>
                            ) : (
                                <p>
                                    К сожалению мы ещё не открылись в вашем
                                    районе. А пока, приглашаем посетить наше
                                    заведение Vita Margarita{' '}
                                    <a
                                        href='https://vitamargarita.ru'
                                        target={'_blank'}
                                        rel='noreferrer'>
                                        https://vitamargarita.ru
                                    </a>{' '}
                                    по адресу ул.Молокова 3Г или сделать заказ
                                    на вынос.
                                </p>
                            )}
                        </div>
                    )}{' '}
                </div>
                <div className='modal-order-address-grid--right'>
                    <div
                        className='modal-order-address__inputs-input-streets-list'
                        ref={streetsListRef}></div>
                </div>
            </div>
        </div>
    )
}
