import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IContent } from '../../interfaces/Content'
import { getContent } from '../actions/content'

interface ContentState {
    contents: IContent[]
    loading: boolean
    error: string
}

const initialState: ContentState = {
    contents: [],
    error: '',
    loading: false
}

export const ContentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
    },
    extraReducers: {
        [getContent.pending.type]: (state) => {
            state.loading = true
        },
        [getContent.fulfilled.type]: (state, action: PayloadAction<IContent[]>) => {
            state.loading = false
            state.contents = action.payload.sort((a, b) => a.order - b.order)
        },
        [getContent.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.error = action.error.message
            state.loading = false
        }
    }
})

export const contentReducer = ContentSlice.reducer
