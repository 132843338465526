import React, { FC } from 'react'
import './Profile.scss'
interface PersonalInfoProps {
    fio: string
    phone: number
    dob: string
}
export const PersonalInfo: FC<PersonalInfoProps> = ({ fio, phone, dob }) => {
    const prettifyPhone = (phone: number) => {
        const p = phone.toString()
        return `+7 (${p[0]}${p[1]}${p[2]}) ${p[3]}${p[4]}${p[5]}-${p[6]}${p[7]}-${p[8]}${p[9]}`
    }
    return (
        <div className='profile__content-personal-info'>
            <h3>Профиль</h3>
            <div className='profile__content-personal-info--grid'>
                <div className='profile__content-personal-info--grid-element --fio-label'>
                    ФИО
                </div>
                <div className='profile__content-personal-info--grid-element --fio-data'>
                    {fio}
                </div>
                <div className='profile__content-personal-info--grid-element --phone-label'>
                    Телефон
                </div>
                <div className='profile__content-personal-info--grid-element --phone-data'>
                    {prettifyPhone(phone)}
                </div>
                <div className='profile__content-personal-info--grid-element --dob-label'>
                    Дата рождения
                </div>
                <div className='profile__content-personal-info--grid-element --dob-data'>
                    {new Date(dob).toLocaleDateString('ru-RU')}
                </div>
            </div>
        </div>
    )
}
