import React, { useEffect, useState } from 'react'
import { OrderSevice } from '../../api/OrderService'
import { IOrder } from '../../interfaces/Order'
import { Order } from './Order'

export const Orders = () => {
    const [orders, setOrders] = useState<IOrder[]>([])
    useEffect(() => {
        let isMount = true
        const getOrders = async () => {
            try {
                const api = new OrderSevice()
                const _orders = await api.getOrders()
                if (isMount) setOrders(_orders.data)
            } catch (e) {
                console.log(e)
            }
        }
        getOrders()
        return () => {
            isMount = false
        }
    }, [])

    return (
        <>
            {orders.map((order) => (
                <Order key={order._id} order={order} />
            ))}
        </>
    )
}
