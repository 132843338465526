import { createAsyncThunk } from '@reduxjs/toolkit'
import { ContentService } from '../../api/ContentService'
import { IApi } from '../../interfaces/Api'
import { IContent } from '../../interfaces/Content'

export const getContent = createAsyncThunk('content/getContent', async() => {
    const api = new ContentService()
    const concept = await api.getContent() as IApi<IContent>
    return concept.data
})
