import React, { FC } from 'react'
import { useImage } from '../../hooks/useImage'
import { IConcept } from '../../interfaces/Concept'
import Placeholder from '../../assets/zag.png'
import './ConceptCard.scss'
interface CardProps {
    currentConcept: IConcept | null
}
export const CurrentConceptCard: FC<CardProps> = ({ currentConcept }) => {
    const { imgSrc } = useImage(currentConcept?.image?.body || '', Placeholder)

    return (
        <div className='concept-card'>
            <div className='concept-card__img'>
                <img src={imgSrc} alt={currentConcept?.name} />
            </div>
            <div className='concept-card__info'>
                <span className='concept-card__info--name'>
                    {currentConcept?.name}
                </span>
                <span className='concept-card__info--address'>
                    {currentConcept?.additionalData?.address}
                </span>
            </div>
        </div>
    )
}
