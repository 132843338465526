import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { IContent } from '../interfaces/Content'
import { Api } from './Api'
import { axiosInstance } from './axios'
export class ContentService extends Api {
    constructor() {
        super()
    }
    protected PATH = 'api/v1/contents'
    
    getContent = async() => {
        try {
            const response = await axiosInstance.get<IApi<IContent>>(`${this.URL}/${this.PATH}/?isDisabled=false&isDeleted=false&isHidden=false&limit=1000`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 422) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
}