import React from 'react'
import { Link } from 'react-router-dom'
import nf from '../../assets/404.png'
import './NotFound.scss'
export const NotFound = () => {
    return (
        <div className='not-found'>
            <div className='not-found__img'>
                <img src={nf} />
            </div>
            <p className='not-found__msg'>
                Страница не найдена, вернуться на{' '}
                <Link className='not-found__msg--link' to={'/'}>
                    главную
                </Link>
                .
            </p>
        </div>
    )
}
