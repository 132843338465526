import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Slider, { Settings } from 'react-slick'
import { scrollIntoView } from 'seamless-scroll-polyfill'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ICategory } from '../../interfaces/Category'
import { productActions } from '../../store/reducers/products'
import { AppDispatch } from '../../store/store'
import './CategoriesCarousel.scss'

interface CategoriesCarouselProps {
    // categories: ICategory[]
    active: string
}
export const CategoriesCarousel: FC<CategoriesCarouselProps> = ({ active }) => {
    const { activeCategory, categories } = useTypedSelector(
        (state) => state.productReducer
    )
    const dispatch = useDispatch<AppDispatch>()
    const [activeElement, setActive] = useState('')
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (categories.length > 0) {
            const _activeCat = categories.find(
                (c) => c._id === activeElement.split('_')[0]
            )
            if (_activeCat) {
                dispatch(
                    productActions.setActiveCategory([
                        _activeCat._id,
                        _activeCat.name,
                        _activeCat.slug,
                    ])
                )
            }
        }
    }, [activeElement])

    useEffect(() => {
        setActive(active)
    }, [active])

    const settings: Settings = {
        dots: false,
        infinite: true,
        focusOnSelect: true,
        speed: 500,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        swipeToSlide: true,
        autoplay: false,
        // afterChange: (current: number) => setActive(current),
    }
    const setActiveCategory = (index: string, c: ICategory) => {
        scrollIntoView(document.getElementById(`category_${c._id}`)!, {
            behavior: 'smooth',
            block: 'start',
        })
        // setActive(index)
        // if (ref.current) {
        //     ref.current.slickGoTo(index)
        // }
        dispatch(productActions.setActiveCategory([c._id, c.name, c.slug]))
    }

    useEffect(() => {
        const elem = document.getElementById(`a_${activeElement}`)
        if (ref.current && elem) {
            ref.current.scrollLeft = elem.offsetLeft
        }
    }, [activeElement])

    return (
        <div ref={ref} className='categories-carousel'>
            {/* <Slider {...settings} ref={ref}> */}
            {categories.map(
                (c, i) =>
                    c.parentCategory === null && (
                        <a
                            href={`#category_${c._id}`}
                            id={`a_category_${c._id}`}
                            className={`categories-carousel__slide ${
                                activeElement === `category_${c._id}`
                                    ? '--active'
                                    : ''
                            }`}
                            key={c._id}
                            onClick={(e) => {
                                setActiveCategory(`category_${c._id}`, c)
                            }}>
                            {c.name}
                        </a>
                    )
            )}
            {/* </Slider> */}
        </div>
    )
}
