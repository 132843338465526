import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import {
    GeolocationControl,
    GeoObject,
    Map,
    YMaps,
    ZoomControl,
} from 'react-yandex-maps'
import { TerminalSevice } from '../../api/TerminalService'
import { IGeoJson } from '../../interfaces/GeoJSON'
import { ITerminal } from '../../interfaces/Terminal'
import { zonesToGeoJson } from '../../utils/toGeoJson'
import './Delivery.scss'
export const Delivery = () => {
    const [terminal, setTerminal] = useState<IGeoJson>()
    const ApiKey = '0611f7be-7352-40e0-8841-9605f16c31fa'
    useEffect(() => {
        let isMount = true

        const getTerminal = async () => {
            try {
                const api = new TerminalSevice()
                const terminals = await api.getTerminals()
                if (isMount)
                    setTerminal(zonesToGeoJson(terminals.data[0].deliveryArea))
            } catch (e) {
                console.log(e)
            }
        }

        getTerminal()
        return () => {
            isMount = false
        }
    }, [])
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Лавка и Доставка домашних продуктов собственного производства. От комплексных обедов, до готовых решений для приготовления блюд дома.'
                />
                <title>Доставка</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='delivery'>
                <div className='delivery__col'>
                    <h3>КАК ЗАКАЗАТЬ? 🚀</h3>

                    <div>
                        <h4>Через сайт:</h4>
                        <ul>
                            <li>
                                Выберете блюда, добавьте в корзину и оформите
                                заказ, указав в форме данные для доставки и
                                оплаты (Полный адрес доставки, номер телефона,
                                комментарии(не обязательно).
                            </li>
                            <li>
                                Желаемое время время для доставки: на ближайшее
                                время, на определённое время, на вынос.
                            </li>
                            <li>
                                Информацию об облате: наличными курьеру,
                                банковской картой курьеру, онлайн оплата на
                                сайте).
                            </li>
                            <li>
                                <b>
                                    Приём заказов на "самовывоз" и "на месте"
                                    принимаются с 09:00 - 22:45
                                </b>
                            </li>
                            <li>
                                Доставка из меню "Завтраки"{' '}
                                <b>не осуществляется</b>, так как блюда могут
                                потерять свой товарный вид. Так как мы заботимся
                                о наших клиентах и качестве продукта меню из
                                категории "Завтраки" принимаются только на
                                "самовывоз и "на месте"
                            </li>
                            <li>
                                Приём заказов из меню "Домашие обеды"
                                осуществляется с 11:00 - 16:00
                            </li>
                            <li>
                                Приём заказов из "Основного меню" осуществляется
                                с 11:00 - 22:45
                            </li>
                        </ul>
                        <br />
                    </div>
                    <div>
                        <h4>Через приложение:</h4>

                        <ul>
                            <li>Установите приложение</li>
                            <li>
                                Пройдите стандартную авторизацию в приложении
                                (введите номер телефона, ваше имя)
                            </li>
                            <li>
                                Выберете блюда, добавьте в корзину и оформите
                                заказ, указав в форме данные для доставки и
                                оплаты (Заполните "окно" Адрес или выберете из
                                сохранённых адресов, комментарии(не
                                обязательно).
                            </li>
                            <li>
                                Желаемое время время для доставки: на ближайшее
                                время, на определённое время, на вынос.
                                Информацию об облате: наличными курьеру,
                                банковской картой курьеру, онлайн оплата на
                                сайте).
                            </li>
                            <li>
                                <b>
                                    Приём заказов на "самовывоз" и "на месте"
                                    принимаются с 09:00 - 22:45
                                </b>
                            </li>
                            <li>
                                Доставка из меню "Завтраки"{' '}
                                <b>не осуществляется</b>, так как блюда могут
                                потерять свой товарный вид. Так как мы заботимся
                                о наших клиентах и качестве продукта меню из
                                категории "Завтраки" принимаются только на
                                "самовывоз и "на месте"
                            </li>
                            <li>
                                Приём заказов из меню "Домашие обеды"
                                осуществляется с 11:00 - 16:00
                            </li>
                            <li>
                                Приём заказов из "Основного меню" осуществляется
                                с 11:00 - 22:45
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h4>По телефону: </h4>
                        Сделать заказ можно по телефону с 09:00 до 22:45. В
                        целях улучшения и контроля качества услуг все разговоры
                        с оператором записываются.
                    </div>
                    <div>
                        <h4>Способы доставки:</h4>
                        Доставка заказов осуществляется курьерами службы
                        доставки Vita Margarita Минимальное время доставки 25
                        минут, максимальное время доставки в "часы ПИК"
                        составляет 40 минут. Доставка осуществляется только по
                        г.Красноярск, район "Взлётка" в радиусе до 3 км. от
                        адреса Молокова 3Г (см. ниже карту доставки)
                        <br />{' '}
                        <b>
                            Минимальная сумма заказа - 500р. Доставка -
                            бесплатно!
                        </b>
                        <br />
                        <br />
                    </div>

                    {/* <p>
                    <b>МИНИМАЛЬНАЯ СУММА ЗАКАЗА - 500 рублей!</b>
                </p>

                <p>Минимальная сумма заказа по правому берегу 500 рублей.</p> */}
                </div>
                {/* <div className='delivery__col'>
                <h3>ОПЛАТА 💰</h3>

                <p>
                    <b>Наличными курьеру</b>
                    <br />
                    Оплата наличными производится курьеру службы доставки при
                    получении заказа.
                </p>

                <p>
                    <b>Банковской картой курьеру</b>
                    <br />
                    Вы можете оплатить заказ банковской картой через мобильный
                    терминал при получении заказа. Мы принимаем карты Visa,
                    MasterCard и Maestro.
                </p>
                <p>
                    <b>Онлайн оплата на сайте или приложении</b>
                    <br />
                    Вы можете оплатить заказ на сайте или приложении.
                </p>
            </div> */}
                <div className='delivery__map'>
                    {terminal ? (
                        <YMaps
                            query={{
                                lang: 'ru_RU',
                                coordorder: 'longlat',
                                apikey: ApiKey,
                            }}>
                            <Map
                                width={'100%'}
                                height={'100%'}
                                defaultState={{
                                    center: [92.92, 56.04],
                                    zoom: 12,
                                }}
                                modules={[
                                    'geoObject.addon.balloon',
                                    'geoObject.addon.hint',
                                ]}>
                                <GeolocationControl
                                    options={{ float: 'left' }}
                                />
                                <ZoomControl
                                    options={{
                                        position: {
                                            right: 10,
                                            left: 'auto',
                                            top: 20,
                                        },
                                        size: 'small',
                                    }}
                                />
                                {terminal?.features?.map((feature, index) => (
                                    <GeoObject
                                        key={`object${index}`}
                                        geometry={feature.geometry}
                                        properties={{
                                            balloonContent:
                                                feature.properties.description,
                                        }}
                                        options={{
                                            geodesic: true,
                                            strokeWidth:
                                                feature.properties[
                                                    'stroke-width'
                                                ],
                                            strokeColor:
                                                feature.properties.stroke,
                                            strokeOpacity:
                                                feature.properties[
                                                    'fill-opacity'
                                                ],
                                            fillColor: feature.properties.fill,
                                            fillOpacity:
                                                feature.properties[
                                                    'fill-opacity'
                                                ],
                                        }}
                                    />
                                ))}
                            </Map>
                        </YMaps>
                    ) : null}
                    <div className='delivery__map-description'>
                        {terminal?.features.map((f) => (
                            <div key={f.id}>
                                <div
                                    style={{
                                        backgroundColor: f.properties.fill,
                                    }}></div>
                                {f.properties.description}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
