import axios, { AxiosError } from 'axios'
import { IApi } from '../interfaces/Api'
import { ITerminal } from '../interfaces/Terminal'
import { Api } from './Api'
import { axiosInstance } from './axios'
export class TerminalSevice extends Api {
    constructor() {
        super()
    }
    protected PATH = 'api/v1/terminals'
    getTerminals = async(conceptId?: string) => {
        try {
            const response = await axiosInstance.get<IApi<ITerminal>>(`${this.URL}/${this.PATH}/?limit=1000`, {
                params: {
                    conceptId
                }
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 404) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
    getTerminalById = async(terminalId: string) => {
        try {
            const response = await axiosInstance.get<ITerminal>(`${this.URL}/${this.PATH}/${terminalId}`)
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if(error.response) {
                if(error.response.status === 404) throw new Error('Not Found')
                if(error.response.status === 404) throw new Error('Validation Error')
                if(error.response.status === 401) throw new Error('Unauthorized')
                if(error.response.status === 500) throw new Error('Сервер не работает')
                throw new Error(error.message)
            } else throw new Error(error.message)
        }
    }
}