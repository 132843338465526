import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './Footer.scss'
import bg from '../../assets/footerbg.jpg'
import vk from '../../assets/vkico.png'
import wa from '../../assets/whatsap.png'
import mir from '../../assets/mir.svg'
import visa from '../../assets/visa.svg'
import alfa from '../../assets/alfa_logo.svg'
import master from '../../assets/mastercard.svg'
import { useTypedSelector } from '../../hooks/useTypedSelector'
export const Footer = () => {
    const { cart } = useTypedSelector((state) => state.cartReducer)
    const location = useLocation()
    const paths = ['/cart', '/order', '/thanks-for-order']
    return (
        <footer
            className={`footer${
                cart.length > 0 && !paths.includes(location.pathname)
                    ? ' --minicart'
                    : ''
            }`}
            style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover' }}>
            <div className='footer__info'>
                <div className='footer__phone'>
                    <p>
                        <a href='tel:+73912716100'>+7 (391) 271-61-00</a>
                    </p>
                    <p>Красноярск, ул. Молокова 3г</p>
                    <p>ООО «ЗАПАД»</p>
                    <p>ИНН 2464139285</p>
                    <p>ОГРН 1172468060940</p>
                    <div className='footer__phone-cards'>
                        <div
                            style={{
                                backgroundImage: `url(${mir})`,
                            }}
                            className='payment-type payment-type--mir'></div>
                        <div
                            style={{
                                backgroundImage: `url(${visa})`,
                            }}
                            className='payment-type payment-type--visa'></div>
                        <div
                            style={{
                                backgroundImage: `url(${master})`,
                            }}
                            className='payment-type payment-type--mc'></div>
                        <div
                            style={{
                                backgroundImage: `url(${alfa})`,
                            }}
                            className='payment-type payment-type--alfa'></div>
                    </div>
                </div>
                <div className='footer__docs'>
                    <a
                        className='footer__link'
                        href={'https://bonus.vitamargarita.ru'}
                        target='_blank'
                        rel='noreferrer'>
                        Программа лояльности
                    </a>
                    <Link className='footer__link' to={'/delivery'}>
                        Доставка и оплата
                    </Link>
                    <Link className='footer__link' to={'/agreement'}>
                        Политика конфиденциальности
                    </Link>
                    <Link className='footer__link' to={'/user-agreement'}>
                        Пользовательское соглашение
                    </Link>
                    <Link
                        className='footer__link'
                        to={'/personality-agreement'}>
                        Согласие на обработку персональных данных
                    </Link>
                </div>
            </div>

            <div className='footer__socials'>
                <a
                    href='https://vk.com/vita_margarita'
                    target={'_blank'}
                    rel='noreferrer'>
                    <img src={vk} />
                </a>
                <a
                    href='https://wa.me/79631915161'
                    target={'_blank'}
                    rel='noreferrer'>
                    <img src={wa} />
                </a>
            </div>
        </footer>
    )
}
