import React, { useEffect, useState } from 'react'
import { AddressService } from '../../api/AddressService'
import { IAddress } from '../../interfaces/Address'
import Close from '../../assets/close.png'
import Pin from '../../assets/pin -3.png'
export const Addresses = () => {
    const [addresses, setAddresses] = useState<IAddress[]>([])
    useEffect(() => {
        let isMount = true
        const getAddresses = async () => {
            try {
                const api = new AddressService()
                const res = await api.getAddresses()
                if (isMount) setAddresses(res.data)
            } catch (e) {
                console.log(e)
            }
        }
        getAddresses()
        return () => {
            isMount = false
        }
    }, [])
    return (
        <div className='profile__content-addresses'>
            {addresses.map((address) => (
                <div
                    key={address._id}
                    className='profile__content-addresses--address'>
                    <img src={Pin} alt='Pin' />
                    <span>{`${address.address.city}, ${address.address.street} ${address.address.home}, ${address.address.flat}`}</span>
                    <img src={Close} alt='Close' />
                </div>
            ))}
        </div>
    )
}
