import { bindActionCreators } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { getCategories } from '../store/actions/categories'
import { getConceptById, getConcepts } from '../store/actions/concept'
import { getContent } from '../store/actions/content'
import { getProfile } from '../store/actions/customer'
import { checkDelivery } from '../store/actions/delivery'
import { getProducts } from '../store/actions/products'
import { AppDispatch } from '../store/store'

export const useActions = () => {
    const dispatch = useDispatch<AppDispatch>()
    return bindActionCreators({
        getCategories,
        getProducts,
        getProfile,
        getConceptById,
        getConcepts,
        checkDelivery,
        getContent
    }, dispatch)
}