import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICustomer, INewBalance } from '../../interfaces/Customer'
import { getProfile } from '../actions/customer'

interface CustomerState {
    customer: ICustomer | null
    address: {
        city: string,
        street: string,
        home: string,
        floor: number
        flat: number
        entrance: number
    } | null
    balance: INewBalance | null
}

const initialState: CustomerState = {
    customer: null,
    // address: null
    address: null,
    balance: null
}

export const customerSlice = createSlice({
    initialState,
    name: 'customer',
    reducers: {
        clear: (state) => {
            state.customer = null
        },
        setAddress: (state, action) => {
            state.address = action.payload
        },
        setBalance: (state, action) => {
            state.balance = action.payload
        }
    },
    extraReducers: {
        [getProfile.fulfilled.type]: (state, action: PayloadAction<[ICustomer, INewBalance]>) => {
            state.customer = action.payload[0]
            state.balance = action.payload[1]
        },
        [getProfile.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.customer = null
            console.error(action.error.message)
            
        }
    }
})

export const customerReducer = customerSlice.reducer
export const customerActions = customerSlice.actions