import React from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import './CustomerInfo.scss'
export const CustomerInfo = () => {
    const { customer } = useTypedSelector((state) => state.customerReducer)
    const toPhoneFormat = (phone: number) => {
        if (phone === 0) return ''
        const phoneStr = phone.toString()
        const code = phoneStr.substring(0, 3)
        const num = `${phoneStr.substring(3, 6)}-${phoneStr.substring(
            6,
            8
        )}-${phoneStr.substring(8)}`
        return `+7 (${code}) ${num}`
    }
    return (
        <div className='customer-info'>
            <h1 className='customer-info__header'>Персональные данные</h1>
            <span className='customer-info__name'>{customer?.firstName}</span>
            <span className='customer-info__phone'>
                {toPhoneFormat(customer?.phone || 0)}
            </span>
        </div>
    )
}
