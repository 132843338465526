import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { OrderSevice } from '../../api/OrderService'
import { CartProduct } from '../../components/cartProduct/CartProduct'
import { IOrder } from '../../interfaces/Order'
import { getWeight } from '../../utils/getWeight'
import './SuccessOrder.scss'
import '../../components/cartProduct/CartProduct.scss'
export const SuccessOrder = () => {
    const [params, _] = useSearchParams()
    const [_order, setOrder] = useState<IOrder>()
    useEffect(() => {
        const id = params.get('orderId')

        let isM = true
        const getOrder = async (_id: string) => {
            try {
                const api = new OrderSevice()
                const order = await api.getOrderById(_id)
                if (isM) setOrder(order)
            } catch (e) {
                console.log(e)
            }
        }

        if (id) {
            getOrder(id)
        }

        return () => {
            isM = false
        }
    }, [])
    const navigate = useNavigate()
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Лавка и Доставка домашних продуктов собственного производства. От комплексных обедов, до готовых решений для приготовления блюд дома.'
                />
                <title>Спасибо за заказ!</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='thanks-for-order'>
                <h1>Спасибо за Ваш заказ!</h1>
                <p>Мы уже обрабатываем ваш заказ</p>
                {/* <div className='thanks-for-order__cart'>
                    {_order?.products.map((product, i) => {
                        return (
                            <div className='cart-item'>
                                <div className='cart-item__name'>
                                    <p>
                                        {product.name}{' '}
                                        (
                    {getWeight(
                        product.weight?.min || 0,
                        product.weight?.full || 0,
                        product.amount
                    )}
                    )
                                    </p>
                                    <small>
                                        {product.modifiers.map(
                                            (m, j) => m.name
                                        )}
                                    </small>
                                </div>

                                <div className='cart-item__counter'>
                                    <span>{product.amount} шт.</span>
                                </div>

                                <div className='cart-item__price'>
                                   
                                    {product.price}₽
                                </div>
                            </div>
                        )
                    })}
                   
                </div>
                <div className='thanks-for-order__total'>
                    <span>Итого</span>
                    <span>{getTotal(products, cart)}₽</span>
                </div> */}
                <button
                    className='thanks-for-order__back'
                    onClick={() => navigate('/')}>
                    На главную
                </button>
            </div>
        </>
    )
}
