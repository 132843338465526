import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// import { IDeliveryCheck } from '../../interfaces/Delivery'
import { DELIVERY_TYPE_COURIER, IDeliveryCheck, IOrder, PAYMENT_TYPE_CARD, SOURCE_ID_TEST } from '../../interfaces/Order'
import { checkDelivery } from '../actions/delivery'
// import { checkDelivery } from '../actions/order/check'

interface OrderState {
    order: IOrder
    orderCheck: IDeliveryCheck | null
}

const initialState: OrderState = {
    order: {
        companyId: '',
        conceptId: '',
        terminalId: null,
        deliveryTypeId: '',
        personsCount: 0,
        paymentTypeId: '',
        deliveryTime: null,
        products: [],
        sourceId: '62f4c57dd950bf8eea039d1b',
        address: {
            city: 'Красноярск',
            street: '',
            home: '',
        },
        withdrawBonuses: 0,
        comment: '',
        callRequest: true
    },
    orderCheck: null
}

export const OrderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        changeState: (state, action: PayloadAction<IOrder>) => {    
            state.order = action.payload
        },
        changePaymentType: (state, action: PayloadAction<string>) => {
            state.order.paymentTypeId = action.payload
        },
        changeDeliveryType: (state, action: PayloadAction<[string, string, string]>) => {
            state.order.deliveryTypeId = action.payload[0]
            state.order.companyId = action.payload[1]
            state.order.conceptId = action.payload[2]
        },
        clearOrder: (state) => {
            state.order = {
                companyId: '',
                conceptId: '',
                terminalId: null,
                deliveryTypeId: '',
                personsCount: 0,
                paymentTypeId: '',
                deliveryTime: null,
                products: [],
                sourceId: '62f4c57dd950bf8eea039d1b',
                address: {
                    city: 'Красноярск',
                    street: '',
                    home: '',
                },
                withdrawBonuses: 0,
                comment: '',
                callRequest: true
            }
        },
        clearOrderCheck: (state) => {
            state.orderCheck = null
        }
    },
    extraReducers: {
        [checkDelivery.pending.type]: (state) => {
            state.orderCheck = null
        },
        [checkDelivery.fulfilled.type]: (state, action: PayloadAction<IDeliveryCheck>) => {
            state.orderCheck = action.payload
        }
    }
})

export const orderReducer = OrderSlice.reducer
export const orderActions = OrderSlice.actions