import { createAsyncThunk } from '@reduxjs/toolkit'
import { OrderSevice } from '../../api/OrderService'

interface Args {
    city: string
    home: string
    street: string
    conceptId: string
}
export const checkDelivery = createAsyncThunk('orders/checkDelivery',
    async(args: Args) => {
        const {home, street, city, conceptId} = args
        const api = new OrderSevice()
        const data = await api.check(city, street, home, conceptId)
        return data
    }
)