import { combineReducers } from '@reduxjs/toolkit'
import { cartReducer } from './cart'
import { conceptReducer } from './concept'
import { contentReducer } from './content'
import { customerReducer } from './customer'
import { orderReducer } from './order'
import { productReducer } from './products'

export const rootReducer = combineReducers({
    productReducer,
    customerReducer,
    cartReducer,
    conceptReducer,
    orderReducer,
    contentReducer
})

export type RootState = ReturnType<typeof rootReducer>