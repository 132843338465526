import React from 'react'
import { Helmet } from 'react-helmet'
import '../agreement/Agreement.scss'
export const PersonalityAgreement = () => {
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Лавка и Доставка домашних продуктов собственного производства. От комплексных обедов, до готовых решений для приготовления блюд дома.'
                />
                <title>Согласие на обработку персональных данных</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='agreement'>
                <div>
                    <h3>Согласие на обработку персональных данных.</h3>
                </div>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp; Пользователь сайта
                    <a href='https://vmdelivery.ru/'>
                        {' '}
                        https://vmdelivery.ru/{' '}
                    </a>{' '}
                    (далее - «Сайт»), оставляя свои персональные данные путем
                    указания своей электронной почты в соответствующем веб-поле
                    после нажатия кнопки «Зарегистрироваться» во исполнение
                    требований Федерального закона от 27.07.2006 г. № 152-ФЗ «О
                    персональных данных» (с изменениями и дополнениями),
                    Федерального закона от 13.03.2006 № 38-ФЗ «О рекламе» с
                    изменениями и дополнениями) свободно, своей волей и в своем
                    интересе:
                </p>{' '}
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp; Подтверждает, что его персональные
                    данные, указанные при регистрации и/или оформления заказа
                    путем заполнения веб-формы на Сайте, направляемой
                    (заполненной) с использованием Сайта, являются достоверными
                    и получены непосредственно от пользователя сайта (являются
                    персональными данными).{' '}
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp; Выражает согласие на передачу
                    персональных данных по открытым каналам связи сети
                    «Интернет».
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp; Выражает согласие с тем, что в
                    рамках обработки персональных данных , вправе осуществлять:
                    сбор, запись, систематизацию, накопление, анализ,
                    использование, извлечение, распространение, передачу любым
                    иным третьим лицам (включая, но не ограничиваясь:
                    организациям владельцам-серверов; организациям, оказывающим
                    услуги по осуществлению звонков, смс - рассылок, любых иных
                    видов рассылок и уведомлений и пр.), получение, обработку,
                    хранение, уточнение (обновление, изменение), обезличивание,
                    блокирование, удаление, уничтожение моих персональных данных
                    путем ведения баз данных автоматизированным, механическим,
                    ручным способами в целях:
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp; ведения и актуализации клиентской
                    базы;
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp; получения и исследования
                    статистических данных об объемах продаж и качестве
                    оказываемых услуг;{' '}
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp; проведению опросов и исследований,
                    направленных на выявление
                    удовлетворенности/неудовлетворенности от уровня
                    предоставляемых услуг в целях их совершенствования;{' '}
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp; информирования меня о проводимых
                    маркетинговых и/или рекламных акциях и иных мероприятий;{' '}
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;рекламирования и иного любого
                    продвижения товаров и услуг;{' '}
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp; технической поддержки при обработке
                    информации, документации и персональных данных с
                    использованием средств автоматизации и без такого
                    использования.{' '}
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp; Выражает согласие на получение
                    информации, в том числе рекламного характера и разрешает
                    осуществлять в адрес пользователя e-mail-рассылки.{' '}
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp; Выражает согласие и разрешает
                    объединять персональные данные в информационную систему
                    персональных данных и обрабатывать персональные данные, с
                    помощью средств автоматизации либо без использования средств
                    автоматизации, а также с помощью иных программных средств,
                    специально разработанных по поручению Оператора.{' '}
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp; Работа с информационными системами
                    персональных данных осуществляется по предписанному
                    алгоритму (сбор, систематизация, накопление, хранение,
                    уточнение, использование, блокирование, уничтожение, др.).
                    Используемые способы обработки, включая, но не
                    ограничиваясь: автоматическая проверка написания названий
                    улиц, населенных пунктов, уточнение данных со мной
                    (посредством почтовой связи, электронной почты, телефонной
                    связи (включая мобильную связь), факсимильной связи, сети
                    Интернет), сегментация информационной базы по заданным
                    критериям, периодические контакты посредством телефонной
                    связи (включая мобильную связь, SMS, посредством
                    информационно-коммуникационных сервисов, таких как Viber,
                    WhatsApp и т.п.), посредством электронной почты или сети
                    Интернет.{' '}
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp; Уведомлен Оператором о том, что
                    предполагаемыми пользователями персональных данных являются
                    работники Оператора.
                </p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;Уведомлен, что: </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp; обработка персональных данных может
                    быть прекращена по запросу пользователя путем направления
                    письменного заявления Оператору по адресу, указанному в
                    данном Согласии, или по электронной почте, если таковая
                    указана на Сайте. имеет право на доступ к персональным
                    данным, требовать уточнения (обновления, изменения)
                    персональных данных, а также удаления и уничтожения
                    персональных данных Оператором посредством направления в
                    адрес Оператора, указанный в пункте 3 настоящего Согласия,
                    соответствующего письменного заявления (составленного в
                    свободной форме).
                </p>
            </div>
        </>
    )
}
