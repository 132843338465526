import axios from 'axios'

export const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(async req => {
    if(req.headers) {
        req.headers['Company-Access-Key'] = 'mvn87exv539qygn878c9qxzumr09xqUG75384Y87FCPQ09CUX8XUWCYT784WY7YW8SAFSs'
        req.headers['Loyalaty-Id'] = '642d368d6c4101abc30980e5'
    }
    return req
})