import { ICart } from '../interfaces/Cart'
import { IProduct } from '../interfaces/Product'

export const getUniqueProducts = (_cart: ICart[], products: IProduct[]): IProduct[] => {
    const set = new Set<string>()
    for (let c of _cart) {
        const _p = products.find((p) => p._id === c.product)?.featured
        if (_p) _p.forEach((p) => set.add(JSON.stringify(p)))
    }

    return Array.from(set.values()).map((s) => JSON.parse(s))
}