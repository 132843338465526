import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { OrderSevice, OrderTypes } from '../../api/OrderService'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { orderActions } from '../../store/reducers/order'
import { AppDispatch } from '../../store/store'

export const PaymentType = () => {
    const { order } = useTypedSelector((state) => state.orderReducer)
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    const [paymentsTypes, setPaymentsTypes] = useState<OrderTypes[]>([])

    const getPaymentTypeById = (id: string) =>
        paymentsTypes.find((t) => t._id === id)
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        let isMount = true
        const getPaymentTypes = async () => {
            try {
                const api = new OrderSevice()
                const _types = await api.getPaymentsTypes(currentConcept!._id)
                console.log(_types)
                if (isMount && _types) {
                    setPaymentsTypes(
                        // _types.filter((t) => !t.isHidden && !t.isDisabled)
                        _types.filter(
                            (t) => t._id !== '63c8f05844402d6705c74577'
                        )
                    )
                    const payment_type = _types.find((t) => t.code === 'card')
                    dispatch(orderActions.changePaymentType(payment_type!._id))
                }
            } catch (e) {
                console.error(e)
            }
        }
        if (currentConcept) getPaymentTypes()
        return () => {
            isMount = false
        }
    }, [])
    return (
        <div className='payment-type'>
            <h1 className='payment-type__header'>Способ оплаты</h1>
            <div className='payment-type__inputs'>
                {paymentsTypes.length > 0 &&
                    paymentsTypes.map((t) => (
                        <div key={t._id}>
                            <input
                                name={'payment-type'}
                                id={t._id}
                                type={'radio'}
                                checked={t._id === order.paymentTypeId}
                                onChange={(e) =>
                                    dispatch(
                                        orderActions.changeState({
                                            ...order,
                                            paymentTypeId: e.target.checked
                                                ? t._id
                                                : order.paymentTypeId,
                                            change:
                                                getPaymentTypeById(t._id)
                                                    ?.code === 'card'
                                                    ? null
                                                    : order.change,
                                        })
                                    )
                                }
                            />
                            <label htmlFor={t._id}>{t.name}</label>
                        </div>
                    ))}
            </div>
            {getPaymentTypeById(order.paymentTypeId)?.code === 'cash' && (
                <div className='payment-type__change'>
                    <label>Сдача с </label>
                    <input
                        type={'number'}
                        min={0}
                        value={order.change?.toString() || 0}
                        onChange={(e) =>
                            dispatch(
                                orderActions.changeState({
                                    ...order,
                                    change: +e.target.value,
                                })
                            )
                        }
                    />
                    <label> ₽</label>
                </div>
            )}
        </div>
    )
}
