import React, { useEffect, useState } from 'react'
import './MenuItems.scss'
import { SubmenuItems } from './SubmenuItems'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IProduct } from '../../interfaces/Product'
import { ProductService } from '../../api/ProductService'
import { ICategory } from '../../interfaces/Category'
import { useActions } from '../../hooks/useActions'
import { isMobile } from 'react-device-detect'
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom'
import { Placeholder } from '../placeholder/Placeholder'
import { useDispatch } from 'react-redux'
import { productActions } from '../../store/reducers/products'

export const findSubCategories = (category: string, categories: ICategory[]) =>
    categories.filter((c) => c.parentCategory === category)

interface SubsOption {
    _id: string
    name: string
    products: IProduct[]
}
export const MenuItems = ({ restId = '' }) => {
    const {
        activeCategory,
        activeSubCategory,
        categories,
        products,
        getProducts: loading,
    } = useTypedSelector((state) => state.productReducer)
    const { getProducts } = useActions()
    const [subs, setSubs] = useState<SubsOption[]>([])
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch()
    useEffect(() => {
        if (currentConcept) getProducts(currentConcept._id)
    }, [currentConcept])
    const nav = useNavigate()
    useEffect(() => {
        if (activeCategory._id) {
            // setSearchParams({ category: activeCategory.slug })
            nav(`/category/${activeCategory.slug}`)
            if (activeSubCategory._id) {
                // setSearchParams({ subCategory: activeSubCategory.slug })
                nav(
                    `/category/${activeCategory.slug}?subCategory=${activeSubCategory.slug}`
                )
                setSubs([
                    {
                        _id: activeSubCategory._id,
                        name: activeSubCategory.name,
                        products: products.filter(
                            (p) => p.categoryId === activeSubCategory._id
                        ),
                    },
                ])
            } else {
                const _subsCat = findSubCategories(
                    activeCategory._id,
                    categories
                )
                dispatch(
                    productActions.setActiveSubCategory([
                        _subsCat[0]?._id || '',
                        _subsCat[0]?.name || '',
                        _subsCat[0]?.slug || '',
                    ])
                )

                if (_subsCat.length !== 0) {
                    setSubs(
                        _subsCat.map((s) => ({
                            _id: s._id,
                            name: s.name,
                            products: products.filter(
                                (p) => p.categoryId === s._id
                            ),
                        }))
                    )
                } else {
                    setSubs([
                        {
                            _id: activeCategory._id,
                            name: '',
                            products: products.filter(
                                (p) => p.categoryId === activeCategory._id
                            ),
                        },
                    ])
                }
            }
        }

        return () => {}
    }, [activeCategory, activeSubCategory, products])

    return (
        <div className='menu-items'>
            {/* <h1 className='menu-items__header'>{activeCategory.name}</h1> */}
            {/* <p className='menu-items__desc'></p> */}
            {loading.loading ? null : subs.length === 0 ? (
                <p className='menu-items__not-found'>
                    В данной категории нет блюд
                </p>
            ) : (
                subs.map((data) => (
                    <SubmenuItems
                        key={data._id}
                        name={data.name}
                        products={data.products.filter(
                            (p) =>
                                p.isDisabled === false && p.isDeleted === false
                        )}
                    />
                ))
            )}
        </div>
    )
}
