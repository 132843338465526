import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import './Modal.scss'
interface ModalProps {
    isShow: boolean
    hide: () => void
    // eslint-disable-next-line no-undef
    children: JSX.Element
}
export const Modal: FC<ModalProps> = ({ isShow, hide, children }) => {
    const modal = (
        <>
            <div className='modal__backdrop' onClick={hide} />
            <div className='modal'>{children}</div>
        </>
    )

    return isShow ? ReactDOM.createPortal(modal, document.body) : null
}
