import { useEffect, useState } from 'react'
import { useTypedSelector } from './useTypedSelector'

export const useSaveCartToLocalStorage = () => {
    const {cart} = useTypedSelector(state => state.cartReducer)
    const {currentConcept} = useTypedSelector(state => state.conceptReducer)
    const [isAccessToSave, setAccess] = useState(true)
    useEffect(() => {
        if(cart.length === 0) return
        const _oldStorage = localStorage.getItem('user_cart')        
        if(_oldStorage) {
            const _oldStorageObj = JSON.parse(_oldStorage)
            if(_oldStorageObj.concept && _oldStorageObj.cart.length > 0 && _oldStorageObj.concept !== currentConcept?._id) {
                // setAccess(false)
                return
            }
        }
        const storage = {
            concept: currentConcept?._id,
            cart
        }
        localStorage.setItem('user_cart', JSON.stringify(storage))
    }, [cart])

    return isAccessToSave
}