import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { TerminalSevice } from '../../api/TerminalService'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ITerminal } from '../../interfaces/Terminal'
import { orderActions } from '../../store/reducers/order'
import { AppDispatch } from '../../store/store'

interface OptionsState {
    value: string
    label: string
}
export const OrderTerminals = () => {
    const [terminals, setTerminals] = useState<ITerminal[]>([])
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    const [selectedOption, setOptions] = useState<OptionsState | null>(null)
    const { order } = useTypedSelector((state) => state.orderReducer)
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        let isMount = true
        dispatch(orderActions.clearOrderCheck())
        const getTerminals = async (id: string) => {
            try {
                const api = new TerminalSevice()
                const _terminals = await api.getTerminals(id)
                if (isMount) {
                    setTerminals(_terminals!.data)
                    setOptions({
                        label: _terminals!.data[0].address,
                        value: _terminals!.data[0]._id,
                    })
                }
            } catch (e) {
                console.error(e)
            }
        }

        if (currentConcept) getTerminals(currentConcept._id)
        return () => {
            isMount = false
        }
    }, [])

    useEffect(() => {
        if (selectedOption) {
            dispatch(
                orderActions.changeState({
                    ...order,
                    terminalId: selectedOption.value,
                    address: null,
                })
            )
        }
    }, [selectedOption])
    return (
        <div className='order-terminals'>
            <h1 className='order-terminals__header'>Адрес самовывоза</h1>
            {terminals.length > 0 && (
                <Select
                    className='order-terminals__select'
                    defaultValue={selectedOption}
                    options={terminals.map((t) => ({
                        value: t._id,
                        label: t.address,
                    }))}
                    onChange={setOptions}
                />
            )}
        </div>
    )
}
