import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { OrderSevice } from '../../api/OrderService'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ICart } from '../../interfaces/Cart'
import { cartActions } from '../../store/reducers/cart'
import { AppDispatch } from '../../store/store'
import { createId } from '../../utils/createId'
import { prepareOrder } from '../../utils/prepareProduct'
import './Promocode.scss'
import ym from 'react-yandex-metrika'

interface IError {
    [key: string]: string
}
const Errors: IError = {
    'total order cost should be more minimal cost.':
        'Минимальная сумма для данного промокода: ',
    // eslint-disable-next-line quotes
    "promocode didn't start or was expired":
        'В данный момент промокод не действует',
    'Promocode not found': 'Промокод не найден',
}

export const Promocode = () => {
    const [code, setCode] = useState('')
    const [error, setError] = useState('')
    const btnRef = useRef<HTMLButtonElement>(null)
    const { cart } = useTypedSelector((state) => state.cartReducer)
    const { products } = useTypedSelector((state) => state.productReducer)
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    const [hasPromo, setHasPromo] = useState(false)
    const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value)
    }

    useEffect(() => {
        let isPromo = false
        cart.forEach((c) => {
            if (c.isPromocode) isPromo = true
        })
        setHasPromo(isPromo)
    }, [cart])

    const dispatch = useDispatch<AppDispatch>()
    const checkPromo = async () => {
        try {
            if (btnRef.current) {
                btnRef.current.disabled = true
                btnRef.current.dataset.loading = 'true'
            }
            setError('')
            const _products = prepareOrder(cart, products)
            const api = new OrderSevice()
            const res = await api.checkPromocode(
                code,
                currentConcept!._id,
                _products
            )
            if (res.product) {
                const _product = res.product
                if (_product) {
                    const _cart: ICart = {
                        count: 1,
                        product: _product._id,
                        modifiers: [],
                        id: createId(_product._id, []),
                        isPromocode: true,
                        price: _product.price,
                        name: _product.name,
                    }
                    dispatch(cartActions.addToCart(_cart))
                    dispatch(cartActions.setAppliedPromocode(code))
                    setCode('')
                    // btnRef.current!.style.backgroundColor = 'gray'
                    // btnRef.current!.style.cursor = 'not-allowed'
                    setError('Промокод активирован')
                    setTimeout(() => {
                        setError('')
                    }, 2000)
                }
            }
        } catch (e: any) {
            setError(`${Errors[e.message] || ''}`)
            if (e.message) {
                const msg = e.message.split(' minSum: ')
                console.log(msg)

                if (msg.length > 1) setError(`${Errors[msg[0]]}${msg[1]}₽`)
                else setError(`${Errors[e.message] || ''}`)
                if (btnRef.current) {
                    btnRef.current.disabled = false
                    btnRef.current.dataset.loading = 'false'
                }
            }
            if (btnRef.current) {
                btnRef.current.disabled = false
                btnRef.current.dataset.loading = 'false'
            }
        } finally {
            if (btnRef.current) {
                btnRef.current.disabled = false
                btnRef.current.dataset.loading = 'false'
            }
        }
    }

    return (
        <div className='promocode'>
            <h2 className='promocode__label'>Промокод</h2>
            <div className='promocode__inputs'>
                <input
                    className='promocode__inputs--input'
                    value={code}
                    onChange={onCodeChange}
                    type='text'
                />
                <button
                    ref={btnRef}
                    disabled={hasPromo}
                    className='promocode__inputs--button'
                    onClick={checkPromo}>
                    Применить
                </button>
            </div>

            {error && <p className='promocode__error'>{error}</p>}
        </div>
    )
}
