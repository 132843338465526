import React, { FC, useEffect, useState } from 'react'
import { ConceptSevice } from '../../api/ConceptService'
import { OrderSevice, OrderTypes } from '../../api/OrderService'
import { TerminalSevice } from '../../api/TerminalService'
import { CurrentConceptCard } from '../../components/currentConceptCard/CurrentConceptCard'
import { RatingStars } from '../../components/UI/ratingStars/RatingStars'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IConcept } from '../../interfaces/Concept'
import { IFeedback, IOrder, IOrderProduct } from '../../interfaces/Order'
import { ITerminal } from '../../interfaces/Terminal'
interface OrderProps {
    order: IOrder
}
export const Order: FC<OrderProps> = ({ order }) => {
    const [deliveryType, setDelType] = useState<OrderTypes>()
    const [terminal, setTerminal] = useState<ITerminal>()
    const [concept, setConcept] = useState<IConcept>()

    const [isOpen, setOpen] = useState(false)
    const [isReadonly, setReadonly] = useState(false)
    const [feedback, setFeedback] = useState<IFeedback>({
        _id: 'newfeedback',
        body: '',
        score: 0,
        customerId: order.customerId as string,
        orderId: order._id as string,
    })

    useEffect(() => {
        let isM = true
        const getTypes = async (
            id: string,
            conceptId: string,
            terminalId: string | null
        ) => {
            try {
                const api = new OrderSevice()
                const terminalApi = new TerminalSevice()
                const conceptApi = new ConceptSevice()
                api.getDeliveryTypes(id).then((res) => {
                    if (isM) {
                        setDelType(
                            res.find((t) => t._id === order.deliveryTypeId)
                        )
                    }
                })
                api.getFeedbackByOrder(order._id as string).then((res) => {
                    if (isM && res.data.length > 0) {
                        setFeedback(res.data[0])
                        setReadonly(true)
                        setOpen(true)
                    }
                })
                conceptApi.getConceptById(conceptId).then((res) => {
                    if (isM) setConcept(res)
                })
                if (terminalId) {
                    const _terminal = await terminalApi.getTerminalById(
                        terminalId
                    )
                    if (isM) setTerminal(_terminal)
                }
            } catch (e) {
                console.log(e)
            }
        }
        getTypes(order.conceptId, order.conceptId, order.terminalId)
        return () => {
            isM = false
        }
    }, [])
    const getPrice = (product: IOrderProduct) => {
        let price = product.price as number
        product.modifiers?.forEach((m) => (price += m.price as number))
        return price
    }
    const getTotal = (products: IOrderProduct[]) => {
        let total = 0
        products.forEach((p) => {
            total += getPrice(p) * p.amount
        })
        return total
    }
    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (feedback.score === 0) return alert('Укажите рейтинг')
        const target = e.target as HTMLButtonElement
        try {
            target.disabled = true
            const api = new OrderSevice()
            const res = await api.addFeedback(
                order._id as string,
                feedback.score,
                feedback.body,
                order.customerId as string
            )
            setReadonly(true)
        } catch (e) {
            console.log(e)
        } finally {
            target.disabled = false
        }
    }
    return (
        <div className='profile__content-order'>
            <div className='profile__content-order__header'>
                <div className='order-header-left'>
                    <div className='order-header-left__number'>{`№ ${order.number}`}</div>
                    <div className='order-header-left__status'></div>
                    <div className='order-header-left__delivery'>
                        {deliveryType?.name}:{' '}
                        {order.address?.city
                            ? `г. ${order.address.city}, ${order.address.street} ${order.address.home}, ${order.address.flat}`
                            : `${terminal?.address || ''}`}
                    </div>
                    <div className='order-header-left__date'>
                        {new Date(order.createdAt!).toLocaleString('ru-RU', {
                            year: 'numeric',
                            month: 'long',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                        })}
                    </div>
                </div>
                <div className='order-header-right'>
                    {concept && <CurrentConceptCard currentConcept={concept} />}
                </div>
            </div>
            <ul className='order-products'>
                {order.products.map((prod) => (
                    <li
                        key={JSON.stringify(prod)}
                        className='order-products__product'>
                        <div>{prod.name}</div>
                        <div>{prod.amount} шт.</div>
                        <div>{getPrice(prod)} ₽</div>
                    </li>
                ))}
                <li className='order-products__product --total'>
                    <div>Итого</div>
                    <div></div>
                    <div>{getTotal(order.products)} ₽</div>
                </li>
            </ul>
            <span className='order-rate' onClick={() => setOpen(true)}>
                Оценить заказ
            </span>
            {isOpen && (
                <form
                    className='order-rate-form'
                    onSubmit={(e) => e.preventDefault()}>
                    <RatingStars
                        id={order._id as string}
                        value={feedback.score}
                        readOnly={isReadonly}
                        onChange={(rating) => {
                            console.log(rating)

                            setFeedback((prev) => ({
                                ...prev,
                                score: rating,
                            }))
                        }}
                    />
                    <label>Комментарий</label>
                    <textarea
                        className='order-rate-form__input'
                        value={feedback.body}
                        readOnly={isReadonly}
                        onChange={(e) =>
                            setFeedback((prev) => ({
                                ...prev,
                                body: e.target.value,
                            }))
                        }
                    />
                    {!isReadonly && (
                        <button
                            className='order-rate-form__submit'
                            onClick={onSubmit}>
                            Отправить
                        </button>
                    )}
                </form>
            )}
        </div>
    )
}
