import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { OrderSevice, OrderTypes } from '../../api/OrderService'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { orderActions } from '../../store/reducers/order'
import { AppDispatch } from '../../store/store'
import { getTotal } from '../../utils/getTotal'
import './MakingOrder.scss'
import { OrderAddresses } from './OrderAddresses'
import { OrderTerminals } from './OrderTerminals'
import { PaymentType } from './PaymentType'
import { PickTime } from './PickTime'
export const MakingOrder = ({ setDelTypes = (types: string[]) => {} }) => {
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    const { order, orderCheck } = useTypedSelector(
        (state) => state.orderReducer
    )
    const { customer, balance } = useTypedSelector(
        (state) => state.customerReducer
    )
    const { cart, cutlery, promocode } = useTypedSelector(
        (state) => state.cartReducer
    )
    const { products } = useTypedSelector((state) => state.productReducer)
    const [deliveryTypes, setDeliveryTypes] = useState<OrderTypes[]>([])
    const getDeliveryTypeById = (id: string) =>
        deliveryTypes.find((d) => d._id === id)
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        let isMount = true
        const getDeliveryTypes = async () => {
            try {
                const api = new OrderSevice()
                const _types = await api.getDeliveryTypes(currentConcept!._id)
                console.log(_types)
                if (isMount && _types) {
                    setDeliveryTypes(_types)
                    setDelTypes(
                        _types
                            .filter((t) => t.code === 'selfService')
                            .map((t) => t._id)
                    )
                    dispatch(
                        orderActions.changeDeliveryType([
                            _types[0]._id,
                            _types[0].companyId,
                            _types[0].conceptId,
                        ])
                    )
                }
            } catch (e) {
                console.error(e)
            }
        }
        if (currentConcept) getDeliveryTypes()
        return () => {
            isMount = false
        }
    }, [])

    const onBonusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const spend = +e.target.value
        if (isNaN(spend)) return
        e.target.style.border = '0'
        if (spend > getMin) {
            e.target.style.border = '1px solid red'
        } else
            dispatch(
                orderActions.changeState({
                    ...order,
                    withdrawBonuses: spend,
                })
            )
    }
    const getMin = useMemo(() => {
        const total = getTotal(products, cart)
        if (!balance) return total
        if (total > balance.total) return balance.total
        return total
    }, [order, balance])
    console.log(getMin)

    return (
        <div className='making-order'>
            <div className='making-order__delivery-types'>
                {deliveryTypes.length > 0 &&
                    deliveryTypes.map((t) => (
                        <button
                            key={t._id}
                            className={`making-order__delivery-types--btn ${
                                order.deliveryTypeId === t._id ? '--active' : ''
                            }`}
                            onClick={() =>
                                dispatch(
                                    orderActions.changeState({
                                        ...order,
                                        deliveryTypeId: t._id,
                                        companyId: t.companyId,
                                        conceptId: t.conceptId,
                                    })
                                )
                            }>
                            {t.name}
                        </button>
                    ))}
            </div>
            {getDeliveryTypeById(order.deliveryTypeId)?.code === 'courier' && (
                <OrderAddresses />
            )}
            {getDeliveryTypeById(order.deliveryTypeId)?.code ===
                'selfService' && <OrderTerminals />}
            <PickTime />
            <PaymentType />
            <div className='making-order__bonus'>
                <h1>Бонусные баллы</h1>
                <label>
                    Вам доступно <b>{balance?.total}</b> баллов!
                </label>
                {getTotal(products, cart) < balance!.total && (
                    <label>
                        Можно списать <b>{getTotal(products, cart)}</b>
                    </label>
                )}
                <div>
                    <label>Списать:</label>
                    <input
                        value={order.withdrawBonuses.toString()}
                        type='text'
                        // max={getMin}
                        onChange={onBonusChange}
                    />
                </div>
            </div>
            <div className='making-order__comment'>
                <label>Комментарий</label>
                <textarea
                    value={order.comment}
                    onChange={(e) =>
                        dispatch(
                            orderActions.changeState({
                                ...order,
                                comment: e.target.value,
                            })
                        )
                    }
                />
            </div>
            {/* <div className='making-order__call-request'>
                <input
                    id='call-request'
                    checked={!order.callRequest}
                    onChange={(e) => {
                        dispatch(
                            orderActions.changeState({
                                ...order,
                                callRequest: !order.callRequest,
                            })
                        )
                    }}
                    type={'checkbox'}
                />
                <label htmlFor='call-request'>Не перезванивать</label>
            </div> */}
        </div>
    )
}
