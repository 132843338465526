import React from 'react'
import './Placeholder.scss'
import Loader from '../../assets/loaderbk.png'
export const Placeholder = () => {
    return (
        <div className='placeholder'>
            <img src={Loader} alt='loader' />
        </div>
    )
}
