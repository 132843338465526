import { IGeoJson } from '../interfaces/GeoJSON'
import { IDeliveryArea } from '../interfaces/Terminal'

const colors = ['#ed4543', '#1bad03', '#ffd21e']
export const zonesToGeoJson = (areas: IDeliveryArea[]): IGeoJson => {
    const geo: IGeoJson = {
        type: '',
        metadata: {
            creator: '',
            name: ''
        },
        features: areas.map((area, index) => {
            return {
                type: 'Feature',
                id: index,
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        area.area.map(a => [a.lon, a.lat])
                    ]
                },
                properties: {
                    description: area.name,
                    fill: colors[index % 3],
                    'fill-opacity': 0.6,
                    stroke: colors[index % 3],
                    'stroke-opacity': 0.9,
                    'stroke-width': '5'
                }
            }
        })
    }
    console.log(geo)
    
    return geo
}
