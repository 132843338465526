import React, { FC } from 'react'
import './RatingStars.scss'
interface RatingStarsProps {
    onChange: (rating: number) => void
    value: number
    id: string
    readOnly?: boolean
}
export const RatingStars: FC<RatingStarsProps> = ({
    onChange,
    value,
    id,
    readOnly = false,
}) => {
    return (
        <div className='container'>
            <div className='rating'>
                <input
                    type='radio'
                    name='rating'
                    id={`rating-5_${id}`}
                    readOnly={readOnly}
                    checked={value === 5}
                    onChange={(e) => {
                        if (!readOnly) onChange(5)
                    }}
                />
                <label htmlFor={`rating-5_${id}`}></label>
                <input
                    type='radio'
                    name='rating'
                    id={`rating-4_${id}`}
                    readOnly={readOnly}
                    checked={value === 4}
                    onChange={(e) => {
                        if (!readOnly) onChange(4)
                    }}
                />
                <label htmlFor={`rating-4_${id}`}></label>
                <input
                    type='radio'
                    name='rating'
                    id={`rating-3_${id}`}
                    readOnly={readOnly}
                    checked={value === 3}
                    onChange={(e) => {
                        if (!readOnly) onChange(3)
                    }}
                />
                <label htmlFor={`rating-3_${id}`}></label>
                <input
                    type='radio'
                    name='rating'
                    id={`rating-2_${id}`}
                    readOnly={readOnly}
                    checked={value === 2}
                    onChange={(e) => {
                        if (!readOnly) onChange(2)
                    }}
                />
                <label htmlFor={`rating-2_${id}`}></label>
                <input
                    type='radio'
                    name='rating'
                    id={`rating-1_${id}`}
                    readOnly={readOnly}
                    checked={value === 1}
                    onChange={(e) => {
                        if (!readOnly) onChange(1)
                    }}
                />
                <label htmlFor={`rating-1_${id}`}></label>
            </div>
        </div>
    )
}
