import React, { FC } from 'react'
import { useImage } from '../../hooks/useImage'
import { ICart } from '../../interfaces/Cart'
import { IProduct } from '../../interfaces/Product'
import Placeholder from '../../assets/zag.png'
import { getWeight } from '../../utils/getWeight'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store/store'
import { cartActions } from '../../store/reducers/cart'
import { getPrice } from '../../utils/getTotal'
import './CartProduct.scss'

interface CartProductProps {
    product: IProduct
    productInCart: ICart
    readOnly?: boolean
}

export const CartProduct: FC<CartProductProps> = ({
    product,
    productInCart,
    readOnly = false,
}) => {
    const { imgSrc } = useImage(product.image?.body || '', Placeholder)
    const dispatch = useDispatch<AppDispatch>()
    return (
        <div className='cart-item'>
            <div>
                <img
                    className='cart-item__image'
                    src={imgSrc}
                    alt={product.name}
                />
            </div>

            <div className='cart-item__name'>
                <p>
                    {productInCart.isPromocode
                        ? `${product.name} (промокод)`
                        : product.name}{' '}
                    (
                    {getWeight(
                        product.weight?.min || 0,
                        product.weight?.full || 0,
                        product.unit
                    )}
                    )
                </p>
                <small>
                    {productInCart.modifiers.map((m, j) =>
                        j < product.modifiers.length - 1
                            ? `${m.option.map((o) => o.name).join(', ')}, `
                            : m.option.map((o) => o.name).join(', ')
                    )}
                </small>
            </div>
            {readOnly ? (
                <div className='cart-item__counter'>
                    <span>{productInCart.count} шт.</span>
                </div>
            ) : (
                <div className='cart-item__counter'>
                    <button
                        className='cart-item__counter--btn-counter'
                        onClick={() =>
                            dispatch(
                                cartActions.decreaseCount([
                                    product,
                                    productInCart.modifiers,
                                ])
                            )
                        }>
                        -
                    </button>
                    <span>{productInCart.count}</span>
                    <button
                        className='cart-item__counter--btn-counter'
                        style={{
                            opacity: productInCart.isPromocode ? 0 : 1,
                        }}
                        onClick={() =>
                            dispatch(
                                cartActions.increaseCount([
                                    product,
                                    productInCart.modifiers,
                                ])
                            )
                        }>
                        +
                    </button>
                </div>
            )}
            <div className='cart-item__price'>
                {getPrice(
                    product.price,
                    product.weight,
                    productInCart.modifiers
                )}
                ₽
            </div>
        </div>
    )
}
