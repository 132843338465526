import { createAsyncThunk } from '@reduxjs/toolkit'
import { ConceptSevice } from '../../api/ConceptService'
import { IApi } from '../../interfaces/Api'
import { IConcept } from '../../interfaces/Concept'

export const getConceptById = createAsyncThunk('concept/getById', async(id: string) => {
    const api = new ConceptSevice()
    const concept = await api.getConceptById(id) as IConcept
    return concept
})

export const getConcepts = createAsyncThunk('concept/getAll', async(slug?: string) => {
    const api = new ConceptSevice()
    const concepts = await api.getConcepts() as IApi<IConcept>
    return [concepts, slug]
})