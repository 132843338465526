import React, { FC } from 'react'
import { IProduct } from '../../interfaces/Product'
import { ProductCard } from '../productCard/ProductCard'
import './SubmenuItems.scss'

interface SubmenuItemsProps {
    name: string
    products: IProduct[]
}
export const SubmenuItems: FC<SubmenuItemsProps> = ({ name, products }) => {
    return (
        <div className='submenu-item'>
            <h2 className='submenu-item__header'>{name}</h2>
            <div className='submenu-item__products'>
                {products.length === 0 ? (
                    <p className='menu-items__not-found'>
                        В данной категории нет блюд
                    </p>
                ) : (
                    products.map((p) => <ProductCard key={p._id} product={p} />)
                )}
            </div>
        </div>
    )
}
