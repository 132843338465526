import { useState } from 'react'

export const useModal = (_isShow = false) => {
    const [isShow, setShow] = useState(_isShow)
    const toggle = () => setShow(!isShow)
    return {
        isShow,
        toggle,
    }
}
