import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApi } from '../../interfaces/Api'
import { ICategory } from '../../interfaces/Category'
import { IProduct } from '../../interfaces/Product'
import { getCategories } from '../actions/categories'
import { getProducts } from '../actions/products'

interface ProductsReducerState {
    activeCategory: {
        name: string
        _id: string
        slug: string
        conceptId: string
    }
    activeSubCategory: {
        name: string
        _id: string
        slug: string
    }
    categories: ICategory[]
    getCategories: {
        loading: boolean
        error: string
    }
    products: IProduct[]
    getProducts: {
        loading: boolean
        error: string
    }
}

const initialState: ProductsReducerState = {
    activeCategory: {
        name: '',
        _id: '',
        slug: '',
        conceptId: ''
    },
    activeSubCategory: {
        name: '',
        _id: '',
        slug: ''
    },
    categories: [],
    getCategories: {
        loading: false,
        error: ''
    },
    products: [],
    getProducts: {
        loading: false,
        error: ''
    }
}
export const productSlice = createSlice({
    initialState,
    name: 'products',
    reducers: {
        setActiveCategory: (state, action: PayloadAction<[string, string, string]>) => { 
            state.activeCategory._id = action.payload[0]
            state.activeCategory.name = action.payload[1]
            state.activeCategory.slug = action.payload[2]
        },
        setActiveSubCategory: (state, action: PayloadAction<[string, string, string]>) => {
            state.activeSubCategory._id = action.payload[0]
            state.activeSubCategory.name = action.payload[1]
            state.activeSubCategory.slug = action.payload[2]
        }
    },
    extraReducers: {
        [getCategories.pending.type]: (state) => {
            state.getCategories.loading = true
            state.getCategories.error = ''
        },
        [getCategories.fulfilled.type]: (state, action: PayloadAction<[IApi<ICategory>, string]>) => {
            state.getCategories.loading = false
            const categories = action.payload[0].data.filter(c => c.isDeleted === false && c.isHidden === false && c.isDisabled === false)
            state.categories = categories
            let active = categories.find(d => d.parentCategory === null && d.conceptId === action.payload[1])
            const { location } = window
            const catParam = location.pathname.split('/')[2]
            let subCatActive: ICategory | undefined = undefined
            if(catParam) {
                active = categories.find(d => d.slug === catParam)
            }
            const subCatParam = location.search.split('=')[1]            
            if(subCatParam) {                                
                subCatActive = categories.find(d => d.slug === subCatParam && d.parentCategory === active?._id)
            }
            
            state.activeCategory = {
                name: active?.name || '',
                _id: active?._id || '',
                slug: active?.slug || '',
                conceptId: active?.conceptId || ''
            }
            state.activeSubCategory = {
                name: subCatActive?.name || '',
                _id: subCatActive?._id || '',
                slug: subCatActive?.slug || ''
            }
        },
        [getCategories.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getCategories.loading = false
            state.getCategories.error = action.error.message
        },
        [getProducts.pending.type]: (state) => {
            state.getProducts.loading = true
            state.getProducts.error = ''
        },
        [getProducts.fulfilled.type]: (state, action: PayloadAction<IApi<IProduct>>) => {
            state.getProducts.loading = false
            state.products = action.payload.data.filter(c => c.isDeleted === false && c.isHidden === false && c.isDisabled === false)
        },
        [getProducts.rejected.type]: (state, action: PayloadAction<any, any, any, Error>) => {
            state.getProducts.loading = false
            state.getProducts.error = action.error.message
        }
    }
})

export const productReducer = productSlice.reducer
export const productActions = productSlice.actions