import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { OrderSevice } from '../../api/OrderService'
import { CartProduct } from '../../components/cartProduct/CartProduct'
import { IOrder } from '../../interfaces/Order'
import { getWeight } from '../../utils/getWeight'
import '../success/SuccessOrder.scss'
import '../../components/cartProduct/CartProduct.scss'
export const FailOrder = () => {
    const navigate = useNavigate()
    return (
        <>
            <Helmet>
                <meta
                    name='description'
                    content='Лавка и Доставка домашних продуктов собственного производства. От комплексных обедов, до готовых решений для приготовления блюд дома.'
                />
                <title>Ошибка заказа</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <div className='thanks-for-order'>
                <h1>Произошла ошибка при оплате заказа!</h1>
                {/* <p>Мы уже обрабатываем ваш заказ</p> */}
                <button
                    className='thanks-for-order__back'
                    onClick={() => navigate('/')}>
                    На главную
                </button>
            </div>
        </>
    )
}
