import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/logobs.png'
import LoginLogo from '../../assets/profil.png'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { DeliveryAddressInput } from '../UI/deliveryAddressInput/DeliveryAddressInput'
import Nav from '../../assets/navigation.png'
import './Header.scss'
import { Modal } from '../UI/modal/Modal'
import { useModal } from '../../hooks/useModal'
import { AddressModal } from './AddressModal'
export const Header = () => {
    const [isHidden, setHidden] = useState(true)
    // const ddStyles = classNames({
    //     'delivery-address-search__dropdown--back': true,
    //     open: !isHidden,
    // })
    const { customer, address, balance } = useTypedSelector(
        (state) => state.customerReducer
    )
    const { isShow, toggle } = useModal()
    return (
        <>
            <header className='header'>
                <div className='header__inner'>
                    <Link className='header__logo' to='/'>
                        <img className='header__logo' src={Logo} alt='logo' />
                    </Link>
                    {/* <DeliveryAddressInput
                    isHidden={isHidden}
                    setHidden={setHidden}
                /> */}
                    <div className='header__nav' onClick={toggle}>
                        <img src={Nav} />
                        <span>
                            {address
                                ? `г. ${address.city}, ${address.street} ${address.home}`
                                : 'Укажите адрес доставки'}
                        </span>
                    </div>
                    {customer ? (
                        <Link className='header__username' to={'/profile'}>
                            <img src={LoginLogo} alt='' />
                            {`${balance?.total} ₽`}
                        </Link>
                    ) : (
                        <Link className='header__signin' to={'/signin'}>
                            <img src={LoginLogo} alt='' />
                            Войти
                        </Link>
                    )}
                </div>
            </header>
            {/* <div className={ddStyles}></div> */}
            <Modal isShow={isShow} hide={toggle}>
                <AddressModal toggle={toggle} />
            </Modal>
        </>
    )
}
