import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import './App.scss'
import { Footer } from './components/footer/Footer'
import { Header } from './components/header/Header'
import { useActions } from './hooks/useActions'
import { useSaveCartToLocalStorage } from './hooks/useSaveCartToLocalStorage'
import { useTypedSelector } from './hooks/useTypedSelector'
import { Agreement } from './pages/agreement/Agreement'
import { Cart } from './pages/cart/Cart'
import { Delivery } from './pages/delivery/Delivery'
import { Main } from './pages/main/Main'
import { Menu } from './pages/menu/Menu'
import { Order } from './pages/order/Order'
import { PersonalityAgreement } from './pages/personalityAgreement/PersonalityAgreement'
import { Product } from './pages/product/Product'
import { Profile } from './pages/profile/Profile'
import { SignIn } from './pages/signin/SignIn'
import { ThanksForOrder } from './pages/thanksForOrder/ThanksForOrder'
import { UserAgreements } from './pages/userAgreement/UserAgreements'
import { cartActions } from './store/reducers/cart'
import { AppDispatch } from './store/store'
import Helmet from 'react-helmet'
import { Modal } from './components/UI/modal/Modal'
import { useModal } from './hooks/useModal'
import Sleep from './assets/sleep.png'
import { DateTime } from 'luxon'
import { NotFound } from './pages/notFound/NotFound'
import { SuccessOrder } from './pages/success/SuccessOrder'
import { FailOrder } from './pages/fail/FailOrder'
function App() {
    const { currentConcept } = useTypedSelector((state) => state.conceptReducer)
    const dispatch = useDispatch<AppDispatch>()
    const { getProfile, getConcepts, getContent } = useActions()
    const location = useLocation()

    const getTime = () => {
        //12:00 - 22:45 -- WORK TIME
        const minH = 12
        const minM = 0
        const maxH = 22
        const maxM = 45

        const startWork = new Date()
        startWork.setHours(minH, minM)

        const endWork = new Date()
        endWork.setHours(maxH, maxM)

        const now = new Date()

        return !(now >= startWork && now < endWork)
    }

    const { isShow, toggle } = useModal(getTime())

    useSaveCartToLocalStorage()
    useEffect(() => {
        if (localStorage.getItem('vita_token')) getProfile()
        const slug = location.pathname.split('/')[1]
        getConcepts(slug)
        getContent()
    }, [])
    useEffect(() => {
        dispatch(cartActions.clear())
    }, [currentConcept?._id])

    // const ref = useRef<HTMLDivElement>(null)

    // useEffect(() => {
    //     if (ref.current)
    //         ref.current.innerHTML =
    //             window.innerHeight + ' ' + window.outerHeight
    // }, [window.innerHeight, window.outerHeight])
    const nav = useNavigate()
    return (
        <>
            <Helmet titleTemplate='%s | VitaMargarita' />
            <Header />
            <Modal isShow={isShow} hide={toggle}>
                <main className='modal-we-are-close'>
                    <header className='modal-we-are-close__header'>
                        <div onClick={toggle}>&#10006;</div>
                    </header>
                    <section className='modal-we-are-close__content'>
                        <div>
                            <img src={Sleep} alt='Кухня уже не работает' />
                        </div>

                        <h2>Кухня уже не работает</h2>
                        <p>В 12:00 мы опять откроемся, чтобы вас накормить.</p>
                    </section>
                </main>
            </Modal>
            <main className='content'>
                <Routes>
                    {/* <Route path='/' element={<Main />} />
                    <Route path='/:slug/menu' element={<Menu />} />
                    <Route path='/:slug/cart' element={<Cart />} />
                    <Route
                        path='/:slug/product/:product_id'
                        element={<Product />}
                    /> */}
                    <Route path='/' element={<Menu />} />
                    <Route path='/category/:slug' element={<Menu />} />
                    <Route path='/cart' element={<Cart />} />
                    <Route path='/product/:product_id' element={<Product />} />
                    <Route path='/order' element={<Order />} />
                    <Route path='/signin' element={<SignIn />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/agreement' element={<Agreement />} />
                    <Route
                        path='/user-agreement'
                        element={<UserAgreements />}
                    />
                    <Route
                        path='/personality-agreement'
                        element={<PersonalityAgreement />}
                    />
                    <Route path='/delivery' element={<Delivery />} />
                    <Route
                        path='/thanks-for-order'
                        element={<ThanksForOrder />}
                    />
                    <Route path='/success' element={<SuccessOrder />} />
                    <Route path='/fail' element={<FailOrder />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </main>
            <Footer />
        </>
    )
}

export default App
