import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { ICart } from '../../interfaces/Cart'
import { IProduct } from '../../interfaces/Product'
import { cartActions } from '../../store/reducers/cart'
import { AppDispatch } from '../../store/store'
import { getWeight } from '../../utils/getWeight'
interface ProductInCartProps {
    product: IProduct
    productInCart: ICart
}
export const ProductInCart: FC<ProductInCartProps> = ({
    product,
    productInCart,
}) => {
    const dispatch = useDispatch<AppDispatch>()
    return (
        <div className='image-wrapper-item'>
            <p className='image-wrapper-item__name'>
                {product.name} (
                {getWeight(
                    product.weight?.min || 0,
                    product.weight?.full || 0,
                    product.unit
                )}
                )
            </p>
            <small>
                {productInCart.modifiers.map((m, j) =>
                    j < product.modifiers.length - 1
                        ? `${m.option.map((o) => o.name).join(', ')}, `
                        : m.option.map((o) => o.name).join(', ')
                )}
            </small>
            <div className='image-wrapper-item__counter'>
                <button
                    className='image-wrapper-item__counter--btn-counter'
                    onClick={() =>
                        dispatch(
                            cartActions.decreaseCount([
                                product,
                                productInCart.modifiers,
                            ])
                        )
                    }>
                    -
                </button>
                <span>{productInCart.count}</span>
                <button
                    className='image-wrapper-item__counter--btn-counter'
                    onClick={() =>
                        dispatch(
                            cartActions.increaseCount([
                                product,
                                productInCart.modifiers,
                            ])
                        )
                    }>
                    +
                </button>
            </div>
        </div>
    )
}
